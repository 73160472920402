$(document).ready(function() {
	const productsSlider = new Swiper('[data-slider-products]', {
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			980: {
				slidesPerView: 3,
				spaceBetween: 60
			}
		}
	});
	const reviewsSlider = new Swiper('[data-slider-reviews]', {
		slidesPerView: 'auto',
		loop: true,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		}
	});

	const categoriesSlider = new Swiper('[data-slider-categories]', {
		loop: false,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			980: {
				slidesPerView: 6,
				spaceBetween: 32
			}
		}
	});


	let productThumbs = new Swiper('[data-product-thumbs]', {
		spaceBetween: 10,
		slidesPerView: 4,
		freeMode: false,
		watchSlidesProgress: true,
	});
	let productSlider = new Swiper('[data-product-full]', {
		spaceBetween: 10,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});

	// search-box open close
	let searchBox = document.querySelector(".search-box");
	let searchButton = document.querySelector(".search .icon-search");
	// let searchBoxCancel = document.querySelector(".search-box .bx-x");

	searchButton.addEventListener("click", ()=>{
		searchBox.classList.toggle("showInput");
		if(searchBox.classList.contains("showInput")){
			searchButton.classList.replace("icon-search" ,"icon-close");
		}else {
			searchButton.classList.replace("icon-close" ,"icon-search");
		}
	});

});
